<template>
  <div class="agent-dialog">
    <el-dialog
      :title="title"
      :visible.sync="showAgent"
      @open="open"
    >
      <el-form :model="data" ref="data">
        <div class="agent-content row">
          <div class="agent-photo">
            <div class="upload">
              <el-upload
                class="upload-demo"
                action="demo"
                :show-file-list="false"
                :http-request="uploadImages"
                v-loading="loadingImg"
                element-loading-spinner="el-icon-loading"
              >
                <img :src="data.avatar ? data.avatar : img" class="agent-img" />
              </el-upload>
            </div>
          </div>
          <div class="agent-table">
            <div class="row name-row">
              <el-form-item>
                <p class="agent-tip">First Name</p>
                <el-input v-model="data.first_name" />
              </el-form-item>
              <el-form-item>
                <p class="agent-tip">Last Name</p>
                <el-input v-model="data.last_name" />
              </el-form-item>
            </div>
            <div class="row move-row">
              <div class="upload">
                <el-upload
                  class="upload-demo"
                  action="demo"
                  :show-file-list="false"
                  :http-request="uploadImages"
                  v-loading="loadingImg"
                  element-loading-spinner="el-icon-loading"
                >
                  <img
                    :src="data.avatar ? data.avatar : img"
                    class="agent-img"
                  />
                </el-upload>
              </div>
              <div class="move-item">
                <el-form-item>
                  <p class="agent-tip">First Name</p>
                  <el-input v-model="data.first_name" />
                </el-form-item>
                <el-form-item>
                  <p class="agent-tip">Last Name</p>
                  <el-input v-model="data.last_name" />
                </el-form-item>
              </div>
            </div>
            <el-form-item>
              <p class="agent-tip">Email</p>
              <el-input
                v-model="data.email"
                :disabled="$route.params.id ? true : false"
              />
            </el-form-item>
            <el-form-item>
              <p class="agent-tip">Phone</p>
              <el-input
                v-model="data.phone"
                maxlength="22"
                @input="(e) => (data.phone = changePhone(e))"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <p class="agent-tip">Title</p>
              <el-input v-model="data.pm.title"></el-input>
            </el-form-item>
            <div class="row" style="margin-bottom: 10px">
              <span class="agent-tip" style="margin-right: 20px"
                >Show on website?</span
              >
              <el-switch
                v-model="data.pm.visible"
                active-color="#678993"
                inactive-color="#ccc"
              ></el-switch>
            </div>
            <el-form-item>
              <p class="agent-tip">Account Type</p>
              <el-select v-model="data.is_admin">
                <el-option label="Agent" value="false"></el-option>
                <el-option label="Administrator" value="true"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <p class="agent-tip">Password</p>
              <el-input
                v-model="data.password"
                show-password
                autocomplete="new-password"
              />
            </el-form-item>
            <el-form-item class="comm">
              <p class="agent-tip">Commission Percentage</p>
              <el-input
                v-model="commission_percentage"
                type="number"
                @input="limitNumber"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
            <div>
              <p class="agent-tip" style="margin-bottom: 1px">Agent Bio</p>
              <tinymce v-model="data.bio" ref="presentation" :height="100" />
            </div>
          </div>
        </div>
        <div class="btn">
          <el-button class="light-blue-button" @click="showAgent = false"
            >Cancel</el-button
          >
          <el-button
            class="primary-button"
            @click="publish('data')"
            :loading="loading"
            >Save</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
function keepTwoDecimal(num) {
  // 采用四舍五入，保存两位小数
  return Math.round(parseFloat(num) * 100) / 100;
}
import {
  apiCreateUser,
  apiGetUser,
  apiUpdateUser,
  apiUploadImage,
} from "../../../API/api";
import Tinymce from "../../tinymce";
import { Minixs } from "../../../js/mixins";
export default {
  mixins: [Minixs],
  components: { Tinymce },
  props: ["title", "contect"],
  data() {
    return {
      showAgent: false,
      id: null,
      data: {
        first_name: "",
        last_name: "",
        email: "",
        bio: "",
        is_active: false,
        avatar: "",
        is_admin: "",
        phone: "",
        pm: { title: null ,visible:false},
      },
      loadingImg: false,
      loading: false,
      commission_percentage: 50,
      img: require("../../../assets/img/person.png"),
    };
  },
  methods: {
    open() {
      if (this.title === "Add New Agent") {
        this.commission_percentage = 50;
        this.data = {
          first_name: "",
          last_name: "",
          email: "",
          bio: "",
          is_active: false,
          avatar: "",
          is_admin: "",
          phone: "",
          pm: { title: null, visible:false, commission_percentage:null },
        };
        this.id = "";
      } else {
        this.data.first_name = this.contect.first_name;
        this.data.last_name = this.contect.last_name;
        this.data.email = this.contect.email;
        this.data.is_admin = this.contect.is_admin;
        this.data.avatar = this.contect.avatar;
        this.data.phone = this.contect.phone ? this.contect.phone : "";
        this.data.bio = this.contect.bio;
        this.id = this.contect.user_id;
        this.data.pm.title = this.contect.pm && this.contect.pm.title ? this.contect.pm.title : null;
        this.data.pm.visible = this.contect.pm.visible
        this.commission_percentage = ( Number(this.contect.pm.commission_percentage) * 100).toFixed(2);
      }
    },
    limitNumber(val) {
      if (val > 100) {
        this.commission_percentage = 100;
      } else if (val < 0) {
        this.commission_percentage = 0;
      }
    },
    publish(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.data.password === "") {
            delete this.data.password;
          }
          if (this.commission_percentage) {
            let age = { commission_percentage: this.commission_percentage };
            let number = Object.assign({}, age);
            let arr = number.commission_percentage / 100;
            this.data.pm.commission_percentage = arr;
          }
          this.loading = true;
          if (this.id) {
            // 修改\
            apiUpdateUser(this.id, this.data).then((res) => {
                this.$message.success("Modification succeeded");
              }).catch((err) => {
                this.$message.error( "request was aborted. " + err.response.data.detail);
              }).finally(() => {
                this.loading = false;
                this.showAgent = false;
              });
          } else {
            // 创建
            apiCreateUser(this.data).then((res) => {
                this.$message.success("Created successfully");
                this.$router.go(0);
              }).catch((err) => {
                this.$message.error( "request was aborted. " + err.response.data.detail);
              }).finally(() => {
                this.loading = false;
                this.showAgent = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    uploadImages(file) {
      let files = new FormData();
      files.append("file", file.file);
      this.loadingImg = true;
      apiUploadImage(files)
        .then((res) => {
          this.data.avatar = res.url;
        })
        .finally(() => {
          this.loadingImg = false;
        });
    },
    initEdit() {
      apiGetUser(this.$route.params.id).then((res) => {
        let obj = { password: "" };
        this.data = res;
        this.data = Object.assign(obj, this.data);
        this.name = res.first_name + " " + res.last_name;
        if (this.data.commission_percentage) {
          this.commission_percentage = keepTwoDecimal(
            this.data.commission_percentage * 100
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/dialog.scss";
@import "../../../css/button.scss";
.agent-dialog {
  /deep/ .mce-ico {
    font-size: 12px;
  }
  /deep/ .mce-menubtn.mce-fixed-width span {
    width: 70px;
  }
  /deep/ .mce-btn button {
    padding: 3px 1px;
  }
  .agent-content {
    margin-top: 20px;
    align-items: flex-start;
    .agent-photo {
      margin-right: 30px;
    }
    .upload {
      text-align: center;
      .agent-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 10px;
        object-fit: cover;
      }
    }
    .agent-table {
      /deep/ input::-webkit-outer-spin-button,
      /deep/ input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      /deep/ input[type="number"] {
        -moz-appearance: textfield;
      }
      .name-row {
        justify-content: space-between;
        /deep/ .el-form-item {
          width: calc(calc(100% - 10px) / 2);
        }
      }
      /deep/ .el-form-item {
        margin-bottom: 15px;
        .el-input,
        .el-select {
          width: 100%;
        }
      }
      .agent-tip {
        margin: 0;
        text-align: left;
        line-height: 18px;
        color: #38425b;
        font-size: "Roboto-Regular", sans-serif;
        font-size: 14px;
      }
    }
    .comm {
      width: 60%;
    }
    .move-row {
      display: none;
      .move-item {
        width: calc(100% - 120px);
      }
    }
  }
  .btn {
    text-align: right;
    margin-top: 20px;
    .el-button {
      width: 100px;
    }
  }
  /deep/ .el-dialog {
    width: 600px;
  }
  @media (max-width: 992px) {
    /deep/ .el-dialog {
      width: 90%;
    }
    .agent-content {
      .comm {
        width: 100%;
      }
      .move-row {
        display: flex;
        justify-content: space-between;
      }
      .agent-photo {
        display: none;
      }
      .agent-table {
        width: 100%;
        .name-row {
          display: none;
        }
      }
    }
  }
}
</style>