<template>
  <div class="agent_box">
    <div class="search">
      <el-input placeholder="Search by name, type, phone" v-model="search"  @keyup.enter.native="clickSearchAgent" class="input-search">
        <i slot="prefix" class="el-input__icon el-icon-search" @click="clickSearchAgent"></i>
      </el-input>
      <el-button @click="createAgent" class="primary-button">+ Add Agent</el-button>
    </div>
    <div class="move">
      <div class="row move-top-row"><span class="agent-title">Agents</span><el-button @click="createAgent" class="primary-button">+ Add Agent</el-button></div>
      <el-input placeholder="Search by name, type, phone" v-model="search"  @keyup.enter.native="clickSearchAgent" class="input-search">
        <i slot="prefix" class="el-input__icon el-icon-search" @click="clickSearchAgent"></i>
      </el-input>
    </div>
    <div class="agent_list" v-loading="loading">
      <Agent-Table :data="data" :total="total" @editAgent="editAgent" @pagesChanged="pagesChanged"></Agent-Table>
    </div>
    <Agent-Dialog ref="show" :title="title" :contect="agentInfo"></Agent-Dialog>
  </div>
</template>
<script>
import { apiGetUsers, apiUpdateUser } from "../../API/api";
import AgentTable from "./component/AgentTable";
import { Minixs } from "../../js/mixins";
import AgentDialog from "./component/AgentsDialog";
export default {
  mixins: [Minixs],
  data() {
    return {
      title: "Add New Agent",
      search: "",
      agentEdit: false,
      agentInfo: {},
      loading: false,
      total: 1, //所有的页面数量
      data: [], //当前显示的数据
      currentPage: 1, //默
      limit: 25,
      count: 0,
    };
  },
  components: { AgentTable, AgentDialog },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    fuzzyQuery() {
      return {
        limit: this.limit,
        offset: this.offset,
        search: this.search,
        user_type: ["pm","admin"],
      };
    },
  },
  methods: {
    // 修改
    editAgent(item) {
      this.title = "Edit Agent";
      this.agentInfo = item;
      this.$refs.show.showAgent = true;
    },
    createAgent() {
      this.agentInfo = null;
      this.title = "Add New Agent";
      this.$refs.show.showAgent = true;
    },
    clickSearchAgent() {
      this.currentPage = 1;
      this.getAgentList();
    },
    clickEdit() {
      this.loading = true;
      this.agentInfo.commission_percentage =
        this.agentInfo.commission_percentage / 100;
      apiUpdateUser(this.agentInfo.user_id, this.agentInfo).then((res) => {
          this.getAgentList();
          this.agentEdit = false;
        }).finally(() => {
          this.loading = false;
        });
    },
    // 获取代理人的列表
    getAgentList() {
      this.loading = true;
      apiGetUsers(this.fuzzyQuery)
        .then((res) => {
          this.data = res.results;
          this.total = res.count;
          this.count = res.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getAgentList();
    },
  },
  created() {
    this.getAgentList();
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
/* 弹出框 */
.agent_box {
  /* search */
  .search {
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: calc(100% - 80px);
    .el-button {
      width: 150px;
    }
    .input-search {
      width: 460px;
      min-width: 200px;
      margin: 20px;
      .el-icon-search {
        color: #678993;
      }
    }
  }
  .agent_list {
    padding: 20px 0px 40px 0px;
    background-color: #fff;
    border-radius: 4px;
    .edit_btn {
      color: #5f99aa;
    }
  }

  /deep/ input[type="number"] {
    -moz-appearance: textfield;
  }
  /deep/ input[type="number"]::-webkit-inner-spin-button,
  /deep/ input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
 .move{
   display: none;
   .move-top-row{justify-content: space-between;margin: 10px 0 20px 0;
   .agent-title{ font-size: 24px;font-family: 'Roboto-Bold';}
   }
 }
  @media (max-width:992px) {
    .search{display: none;}
    .move{display: block;}
    .agent_list{margin-top: 20px;}
  }
}
</style>
